import { z } from "zod"
import { CompanyLineItem } from "../domain/company-line-item"
import { useAuth } from "../../auth/use-auth"
import { useForm } from "../../hooks/useForm"
import { useMutation } from "@tanstack/react-query"
import { createCompanyLineItem } from "../actions/create-company-line-item"
import { Form } from "../../layout/form/form"
import ModalDialog from "../../layout/modal-dialog"
import { SelectField } from "../../layout/form/select-field"
import { LineItemsType } from "../../services/line-items.service"
import { orderBy } from "lodash"
import { TextAreaField } from "../../layout/form/text-area-field"
import { CurrencyField } from "../../layout/form/currency-field"
import { useEffect } from "react"
import { Currency } from "../../billing/components"

const schema = z.object({
    lineItemId: z.number(),
    notes: z.string().nullish(),
    amount: z.number(),
})

type Data = z.infer<typeof schema>

export const NewLineItemModal = ({
    onClose,
    onCreate,
    companyId,
    lineItems,
}: {
    onClose: () => void
    onCreate: (companyLineItem: CompanyLineItem) => void
    companyId: number
    lineItems: LineItemsType[]
}) => {
    const auth = useAuth()
    const authToken = auth.expectAuthToken()
    const form = useForm({ schema })

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const companyLineItem = await createCompanyLineItem({ authToken, data: { ...data, companyId } })
            return companyLineItem
        },
    })

    const handleSubmit = form.handleSubmit(async (data) => {
        const companyLineItem = await mutation.mutateAsync(data)
        onCreate(companyLineItem)
    })

    const lineItemOptions = orderBy(
        lineItems.map((li) => ({ label: li.name, value: li.id as number })),
        (op) => op.label,
    )

    const lineItemId = form.watch("lineItemId")
    const lineItem = lineItems.find((li) => li.id === lineItemId)

    return (
        <Form onSubmit={handleSubmit} error={mutation.error as any}>
            <ModalDialog title="New Line Item" show close={onClose} okAction={handleSubmit} okText="Add Line Item">
                <SelectField label="Line Item" options={lineItemOptions} {...form.getFieldProps("lineItemId")} />
                {lineItem && (
                    <div style={{ color: "gray" }} className="mb-4">
                        Default price: <Currency amount={lineItem?.price} />
                    </div>
                )}
                <CurrencyField label="Price" {...form.getFieldProps("amount")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
            </ModalDialog>
        </Form>
    )
}
