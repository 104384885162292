import { z } from "zod"
import { CompanyLineItem } from "../domain/company-line-item"
import { useAuth } from "../../auth/use-auth"
import { useForm } from "../../hooks/useForm"
import { useMutation } from "@tanstack/react-query"
import { Form } from "../../layout/form/form"
import ModalDialog from "../../layout/modal-dialog"
import { SelectField } from "../../layout/form/select-field"
import { LineItemsType } from "../../services/line-items.service"
import { orderBy } from "lodash"
import { TextAreaField } from "../../layout/form/text-area-field"
import { CurrencyField } from "../../layout/form/currency-field"
import { useEffect } from "react"
import { updateCompanyLineItem } from "../actions/update-company-line-item"

const schema = z.object({
    lineItemId: z.number(),
    notes: z.string().nullish(),
    amount: z.number(),
})

type Data = z.infer<typeof schema>

export const EditLineItemModal = ({
    onClose,
    onUpdate: onUpdate,
    companyLineItem: companyLineItem,
    lineItems,
}: {
    onClose: () => void
    onUpdate: (companyLineItem: CompanyLineItem) => void
    companyLineItem: CompanyLineItem
    lineItems: LineItemsType[]
}) => {
    const auth = useAuth()
    const authToken = auth.expectAuthToken()
    const form = useForm({
        schema,
        defaultValues: {
            amount: companyLineItem.amount || undefined,
            lineItemId: companyLineItem.lineItemId,
            notes: companyLineItem.notes,
        },
    })

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompanyLineItem = await updateCompanyLineItem({
                authToken,
                companyLineItemId: companyLineItem.id as number,
                data: { ...data },
            })
            return updatedCompanyLineItem
        },
    })

    const handleSubmit = form.handleSubmit(async (data) => {
        const updatedCompanyLineItem = await mutation.mutateAsync(data)
        onUpdate(updatedCompanyLineItem)
    })

    const lineItemOptions = orderBy(
        lineItems.map((li) => ({ label: li.name, value: li.id as number })),
        (op) => op.label,
    )

    const lineItemId = form.watch("lineItemId")

    useEffect(() => {
        const lineItem = lineItems.find((li) => li.id === lineItemId)
        if (lineItem) {
            form.setValue("amount", lineItem?.price || 0)
        }
    }, [lineItemId, lineItems])

    return (
        <Form onSubmit={handleSubmit} error={mutation.error as any}>
            <ModalDialog
                title="Update Line Item"
                show
                close={onClose}
                okAction={handleSubmit}
                okText="Update Line Item"
            >
                <SelectField label="Line Item" options={lineItemOptions} {...form.getFieldProps("lineItemId")} />
                <CurrencyField label="Amount" {...form.getFieldProps("amount")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
            </ModalDialog>
        </Form>
    )
}
