import { useEffect, useState } from "react";
import { Select } from "../../layout/form/select-input";
import ModalDialog from "../../layout/modal-dialog";
import { LineItemsType } from "../../services/line-items.service";
import { Currency } from "../../billing/components";
import ButtonNeoGen from "../../layout/button-neogen";
import { CurrencyInput } from "../../layout/form/currency-input";
import { NumberInput } from "../../layout/form/number-input";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { z } from "zod";
import { useForm } from "../../hooks/useForm";
import { Company } from "../domain/company";
import { useMutation } from "@tanstack/react-query";
import { updateCompany } from "../actions/update-company";
import { createInvoice } from "../../invoices/actions/create-invoice";
import { useAuth } from "../../auth/use-auth";
import { SelectField } from "../../layout/form/select-field";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { addDays } from "date-fns";

const billingDetailsFormSchema = z.object({
    lineItemId: z.number(),
    businessName: z.string(),
});

type Data = z.infer<typeof billingDetailsFormSchema>;

export const NewInvoiceFlowModal = ({
    onClose,
    lineItems,
    company,
}: {
    onClose: () => void;
    lineItems: LineItemsType[];
    company: Company;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [discountAmount, setDiscountAmount] = useState<number>(0);
    const [discountPercent, setDiscountPercent] = useState<number>(0);
    const [discountType, setDiscountType] = useState<"amount" | "percentage">("amount");
    const [paymentTerms, setPaymentTerms] = useState<"upfront" | "30days" | "60days" | "90days" | "120days">("upfront");

    const form = useForm({
        schema: billingDetailsFormSchema,
        defaultValues: { businessName: company.name || undefined },
    });
    const formValues = form.watch();

    const paymentTermOptions = [
        {
            value: "upfront",
            label: "Upfront",
            days: 0,
        },
        {
            value: "30days",
            label: "30 Days",
            days: 30,
        },
        {
            value: "60days",
            label: "60 Days",
            days: 60,
        },
        {
            value: "90days",
            label: "90 Days",
            days: 90,
        },
        {
            value: "120days",
            label: "120 Days",
            days: 120,
        },
    ];

    const packageOptions = lineItems
        .filter((li) =>
            [
                "Tax Attorney Opinion Letter",
                "ERC Repair Enhanced Evaluation ",
                "ERC Repair Enhanced Analysis with calculation  review",
                "ERC Repair Complete Enhanced Package with Calculations",
                "ERC Repair Complete Enhanced Package",
            ].includes(li.name),
        )
        .map((li) => ({ label: li.name, value: li.id as number }));

    const selectedLineItem = lineItems.find((li) => li.id === formValues.lineItemId);
    const basePrice = selectedLineItem?.price || 0;
    const finalPrice = basePrice - discountAmount;

    useEffect(() => {
        if (discountType === "amount") {
            setDiscountPercent((discountAmount / basePrice) * 100);
        }
        if (discountType === "percentage") {
            setDiscountAmount((discountPercent / 100) * basePrice);
        }
    }, [basePrice]);

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            if (data.businessName !== company.name) {
                await updateCompany({ authToken, id: company.id, data: { name: data.businessName } });
            }

            const dueInDays = paymentTermOptions.find((pt) => pt.value === paymentTerms)?.days;
            const dueDate = dueInDays ? addDays(new Date(), dueInDays) : undefined;

            const invoice = await createInvoice({
                authToken,
                data: {
                    companyId: company.id,
                    ...(dueDate ? { dueDate } : {}),
                    lineItems: [{ lineItemId: data.lineItemId, price: finalPrice }],
                },
            });
            return { invoice };
        },
    });

    const handleSubmit = async (data: Data) => {
        await mutation.mutateAsync(data);
    };

    const invoice = mutation.data?.invoice;

    return (
        <ModalDialog show close={onClose} title="Issue Invoice To Customer" showOk={false} showCancel={false}>
            {invoice ? (
                <div className="flex flex-col gap-4">
                    <div style={{ textAlign: "center", fontSize: 22 }}>Invoice has been issued to the customer.</div>
                    <div style={{ textAlign: "center" }}>
                        Please check #payment-notifications on Slack to see once the customer has paid.
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <a
                            href={`https://dashboard.stripe.com/${
                                ["localhost", "staging"].includes(window.location.hostname) ? "test/" : ""
                            }invoices/${invoice.stripeInvoiceId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            View invoice {invoice.invoiceNumber} on Stripe
                        </a>
                    </div>
                </div>
            ) : (
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={mutation.error as any}>
                    <div className="flex flex-col gap-5">
                        <div>
                            <h2>Select Package</h2>
                            <SelectField options={packageOptions} {...form.getFieldProps("lineItemId")} />
                            <div style={{ fontWeight: 600, marginTop: -15 }}>
                                Base Price: {selectedLineItem && <Currency amount={basePrice} />}
                            </div>
                        </div>
                        <div>
                            <h2>Apply Discount (Optional)</h2>
                            <div>
                                <div className="flex justify-between gap-4 items-center">
                                    <div>
                                        Discount Amount
                                        <CurrencyInput
                                            value={discountAmount}
                                            onChange={(e) => {
                                                setDiscountType("amount");
                                                const amount = Number.parseFloat(e.target.value);
                                                setDiscountAmount(amount);
                                                const percent = (amount / basePrice) * 100;
                                                setDiscountPercent(percent);
                                            }}
                                        />
                                    </div>
                                    <div>Or</div>
                                    <div>
                                        Discount Percentage
                                        <NumberInput
                                            value={discountPercent}
                                            onChange={(e) => {
                                                setDiscountType("percentage");
                                                const percent = Number.parseFloat(e.target.value);
                                                setDiscountPercent(percent);
                                                setDiscountAmount(basePrice * (percent / 100));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ fontWeight: 600 }}>
                                Discounted Price: {selectedLineItem && <Currency amount={finalPrice} />}
                            </div>
                        </div>
                        <div>
                            <h2>Set Payment Terms</h2>
                            <div>
                                <Select
                                    options={paymentTermOptions}
                                    value={paymentTerms}
                                    onChange={(e) => {
                                        setPaymentTerms(e as any);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <h2>Verify Billing Details</h2>
                            <TextField label="Business Name" {...form.getFieldProps("businessName")} />
                        </div>
                        <div>
                            <h2>Summary</h2>
                            <div>
                                <table style={{ tableLayout: "fixed", width: "100%", borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid lightgray" }}>
                                            <td>Base Price</td>
                                            <td style={{ textAlign: "right" }}>
                                                <div>
                                                    <Currency amount={basePrice} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid lightgray" }}>
                                            <td>Discount</td>
                                            <td style={{ textAlign: "right", color: "gray" }}>
                                                {discountAmount ? (
                                                    <>
                                                        -<Currency amount={discountAmount} />
                                                    </>
                                                ) : (
                                                    <Currency amount={discountAmount} />
                                                )}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid lightgray" }}>
                                            <td>Final Price</td>
                                            <td style={{ fontSize: 18, textAlign: "right", fontWeight: "bold" }}>
                                                <Currency amount={finalPrice} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex justify-end gap-4">
                            <ButtonNeoGen type="submit" isLoading={mutation.isLoading}>
                                Issue Invoice
                            </ButtonNeoGen>
                        </div>
                    </div>
                </Form>
            )}
        </ModalDialog>
    );
};
