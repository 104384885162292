import { z } from "zod";

export const invoiceSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date().optional(),
    updatedAt: z.coerce.date().optional(),
    issuedAt: z.coerce.date().nullish(),
    companyId: z.number(),
    invoiceNumber: z.string().nullish(),
    description: z.string().nullish(),
    status: z.string().nullish(),
    amount: z.number().nullish(),
    stripeInvoiceId: z.string().nullish(),
    dueDate: z.coerce.date().nullish(),
});

export type Invoice = z.infer<typeof invoiceSchema>;
