import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Loader2 from "../sections/utilities/Loader2";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Loader3 from "../sections/utilities/Loader3";

function ModalDialog(props: ModalNeoGenProps) {
    const cancelButtonRef = useRef(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (props.isSaveDialog && props.saved) {
            setIsSaving(false);
            props.close();
        } else {
            setIsSaving(false);
        }
    }, [props]);

    if (!props.show) {
        return null;
    }

    let size = "md:w-1/4 xxl:w-96";
    switch (props.size) {
        case "xs":
            size = "md:w-1/5 xxl:w-1/6";
            break;
        case "sm":
            size = "md:w-1/3 xxl:w-1/6";
            break;
        case "md":
            size = "md:w-1/2 xxl:w-1/4";
            break;
        case "lg":
            size = "md:w-3/4 xxl:w-1/2";
            break;
        case "xl":
            size = "xl:w-4/5";
            break;
    }

    function okClicked() {
        if (props.isSaveDialog && props.okAction) {
            setIsSaving(true);
            props.okAction();
        } else {
            if (props.okAction) {
                props.okAction();
            }
        }
    }

    return (
        <>
            <Transition.Root show={props.show} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="absolute top-0 z-50 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={props.show}
                    onClose={() => props.close()}
                >
                    <div
                        className={
                            "flex items-end justify-center min-h-screen" +
                            (props.noPadding ? "" : " pt-4 px-0 pb-20 ") +
                            " text-center sm:block sm:p-0"
                        }
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed top-0 inset-0 bg-gray-500 bg-opacity-75 bottom-0 transition-opacity z-2" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className={
                                    "w-full m-2 inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg z-50 " +
                                    (props.noPadding ? "px-0" : "px-4 pt-5 pb-4  sm:my-8 ") +
                                    " text-left shadow-xl transform transition-all sm:align-middle " +
                                    size +
                                    " " +
                                    (props.noPadding ? "sm:p-0" : "sm:p-6")
                                }
                            >
                                <div className="z-20">
                                    <div className="mt-0  ">
                                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white dark:bg-gray-900 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:dark:ring-indigo-900 focus:ring-offset-2"
                                                onClick={() => props.close()}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-xl leading-6 text-center font-medium text-gray-900 dark:text-gray-500 mb-4 tracking-wide"
                                        >
                                            {props.title}
                                        </Dialog.Title>
                                        <div className={props.noPadding ? "" : "mt-2"}>
                                            {isSaving ? (
                                                <>
                                                    <div className="flex justify-center">
                                                        Please Wait, Saving...
                                                        <Loader2 />
                                                    </div>
                                                </>
                                            ) : (
                                                props.children
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={props.noPadding ? "" : "flex justify-end mt-5 sm:mt-0"}>
                                    {props.showCancel !== false && (
                                        <button
                                            type="button"
                                            className="py-2 px-4  sx:mt-3 ring-0  rounded-md border border-gray-300 dark:border-gray-600 shadow-sm dark:bg-gray-800  bg-white text-base font-medium text-gray-700 dark:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-indigo-500 sm:mt-0  sm:text-sm"
                                            onClick={() => props.close()}
                                            ref={cancelButtonRef}
                                        >
                                            {props.cancelText ?? "Cancel"}
                                        </button>
                                    )}
                                    {props.showOk !== false && (
                                        <button
                                            type="button"
                                            className="py-2 px-4 ml-3 ring-0 rounded-md border border-transparent shadow-sm  bg-indigo-600 dark:bg-indigo-800 dark:text-gray-300 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                            onClick={() => okClicked()}
                                            disabled={props.disabled || props.isLoading}
                                        >
                                            {props.isLoading
                                                ? "Loading..."
                                                : isSaving
                                                ? "Saving..."
                                                : props.okText ?? "Ok"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default ModalDialog;

type ModalNeoGenProps = {
    close: () => void;
    show: boolean;
    children: any;
    title: string | JSX.Element;
    okText?: string;
    cancelText?: string;
    okAction?: () => void;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    showOk?: boolean;
    showCancel?: boolean;
    noPadding?: boolean;
    isSaveDialog?: boolean;
    saved?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
};
