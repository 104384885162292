import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils"
import { CompanyLineItem, companyLineItemSchema } from "../domain/company-line-item"

export type UpdateCompanyLineItemData = Partial<CompanyLineItem>

export const updateCompanyLineItem = async ({
    authToken,
    companyLineItemId,
    data,
}: {
    authToken: string
    companyLineItemId: number
    data: UpdateCompanyLineItemData
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/company-line-items/${companyLineItemId}`,
        authToken,
        data,
    })

    const companyLineItem = parseSchema(companyLineItemSchema, response.data)

    return companyLineItem
}
